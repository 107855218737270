
    import { mapState, mapActions, mapMutations } from 'vuex';
    import SearchPushUrlMixin from '@/components/Search/SearchPushUrlMixin';
    import SearchMix from '@/components/Search/SearchMixin';
    import AdUnit from '@/components/Ad/AdUnit';

    export default {
        layout: 'discovery',

        components: {
            AdUnit,
        },

        mixins: [SearchPushUrlMixin, SearchMix],

        data() {
            return {};
        },

        computed: {
            ...mapState({
                $_global: state => state.global,
                $_appInfo: state => state.appInfo,
                $_me: state => state.me.user,
                $_search: state => state.search,
            }),
        },

        watch: {
            $_meAttributeOptions: {
                handler() {
                    this.$_syncScoring();
                },
                deep: true,
            },
        },

        mounted() {
            this.getSearchPayload();
            this.updateMe();
            this.$store.commit('search/SET', { key: 'activeSearchFilter', value: false });
        },

        methods: {
            ...mapActions('me', {
                $_syncScoring: 'syncScoring',
                $_attachAttribute: 'attachAttribute',
                $_getJobEvents: 'getJobEvents',
            }),

            ...mapActions('job', {
                $_searchJobs: 'searchJobs',
            }),

            ...mapActions('brand', {
                $_getBrandEeoTags: 'getBrandEeoTags',
            }),

            ...mapMutations('me', {
                $_PUT_ATTRIBUTE_OPTION: 'PUT_ATTRIBUTE_OPTION',
                $_SAVE_ATTRIBUTE_OPTION_AFTER_LOGIN: 'SAVE_ATTRIBUTE_OPTION_AFTER_LOGIN',
            }),

            ...mapMutations('search', {
                $_SET_SEARCH_PAGE_TEMPLATE: 'SET_SEARCH_PAGE_TEMPLATE',
                $_SET: 'SET',
                $_SET_SEARCH_OBJECT: 'SET_OBJECT',
            }),

            async getSearchPayload() {
                if (!this.$_search.searchPayload.initSearchData) {
                    const url = this.$route.fullPath;
                    const allowBackFill = url.search('/br_') <= 0;
                    this.searchPayload = await this.$_searchJobs({
                        url,
                        locale: 'AU',
                        allow_backfill: allowBackFill,
                    }).then().catch((e) => {
                        if (e.name === 'TypeError [ERR_UNESCAPED_CHARACTERS]' || e.response.status === 404 || e.response.status === 422) {
                            const url = `${this.$_currentAppUrl}/not-found`;
                            this.$route.redirect(url);
                        }
                        return true;
                    });
                    this.$_SET_SEARCH_OBJECT({
                        key: 'searchPayload',
                        value: this.searchPayload,
                    });
                    this.$_getBrandEeoTags().then((response) => {
                        this.$_SET({
                            key: 'availableEeoTags',
                            value: response.data,
                        });
                    });
                } else {
                    this.searchPayload = this.$_search.searchPayload.initSearchData;
                }
            },

            async updateMe() {
                if (this.$_me.id) {
                    const attributeOptionAfterLogin = this.$_me.saveAttributionOptionAfterLogin;
                    if (attributeOptionAfterLogin) {
                        const data = {
                            attribute_option_id: attributeOptionAfterLogin.attributeOption.id,
                        };
                        const attributeOption = await this.$_attachAttribute({
                            id: attributeOptionAfterLogin.attributeId,
                            data,
                        });
                        this.$_PUT_ATTRIBUTE_OPTION(attributeOption.data);
                        this.$_SAVE_ATTRIBUTE_OPTION_AFTER_LOGIN('');
                    }
                    await this.$_syncScoring();
                    await this.$_getJobEvents();
                }
            },
        },

        head() {
            const browseMeta = [
                { hid: 'og:title', property: 'og:title', content: 'Jobs In Australia - Find Your Next Career Opportunity' },
                {
                    hid: 'description',
                    name: 'description',
                    content: `Search the latest jobs to find your dream job. Whatever you're looking for, you can find it on ${this.$siteName}! Browse now with new jobs added daily!`,
                },
                {
                    hid: 'og:description',
                    property: 'og:description',
                    content: `Search the latest jobs to find your dream job. Whatever you're looking for, you can find it on ${this.$siteName}! Browse now with new jobs added daily!`,
                },
            ];
            if (this.$route.name !== 'jobs') {
                browseMeta.push({
                    hid: 'robots',
                    name: 'robots',
                    content: 'noindex, follow',
                });
            }
            return {
                title: `Jobs In Australia - Find Your Next Career Opportunity | ${this.$siteName}`,
                meta: browseMeta,
            };
        },
    };
